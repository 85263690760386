import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";
import { Heading, Title, TwoColContainer, Col1Image, Col2Info } from '../components/Layout/pageParts';
import Layout from "../components/Layout/layout"

const ThanksPage = () => {

    const data = useStaticQuery(graphql`
    query {
      fileName: file(relativePath: { eq: "bg3.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }
    }
`)

    return (    
        <Layout>
          <TwoColContainer>
            <Col1Image>
              <Img alt="thanks" fluid={data.fileName.childImageSharp.fluid} style={{ width: "100%", height: "100%" }} imgStyle={{ objectPosition: 'left' }} />
            </Col1Image>
            <Col2Info>
              <Heading>Thanks</Heading>
              <Title>I'll be in touch very soon</Title>
            </Col2Info>
          </TwoColContainer>
        </Layout>    
)};

export default ThanksPage;